import React from "react";
import { SideBarMenuItem } from "./types/types"; 
import { classNames } from "./util/classes";
import './styles/noMobile/SideBarMenuItemView.scss';

export default function SideBarMenuItemView({key, item, isOpen}:SideBarMenuItemViewProps){
    return( <div className='SideBarMenuItemView'>
                <a href={item.url}>
                    <div className={classNames('ItemContent',isOpen?'':'collapsed')}>
                        <div className="icon">
                            <item.icon size={32}/>
                        </div>
                        <span className="label">
                            {item.label}
                        </span>
                    </div>
                </a>
                {
                    !isOpen?<div className="tooltip">{item.label}</div>:''
                }
            </div>);
}

interface SideBarMenuItemViewProps{
    key: string,
    item: SideBarMenuItem,
    isOpen: boolean,
}