import { SideBarMenuCard, SideBarMenuItem } from "./types/types";
import { useState } from "react";
import { classNames, isMobile } from "./util/classes";
import SideBarMenuCardView from "./SideBarMenuCardView";
import SideBarMenuItemView from "./SideBarMenuItemView";
import "./styles/noMobile/SideBarMenu.scss";

import {VscMenu} from "react-icons/vsc"


export function SideBarMenu({items, card}:SideBarMenuProps){
    let mobile = isMobile();
    const [isOpen, setIsOpen] = useState<boolean>(!mobile);
    function handleClick(){
        setIsOpen(!isOpen);
    }
    
    return(
        <div className={classNames(mobile?'SideBarMenu':'SideBarMenu', isOpen? 'expanded':'collapsed')}>
            <div className="menuButton">
                <button className="hamburgerButton" onClick={handleClick}>
                    <VscMenu/>
                </button>
            </div>
            <SideBarMenuCardView card={card} isOpen={isOpen}/>
            {
                items.map(item => (
                    <SideBarMenuItemView key = {item.id} 
                                         item = {item} 
                                         isOpen={isOpen}/>
                ))
            }
        </div>
    )
}

interface SideBarMenuProps{
    items: SideBarMenuItem[];
    card:SideBarMenuCard;
}

