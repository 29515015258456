import React from 'react';
import { SideBarMenu } from './SideBarMenu';
import {card} from './data/dataMenu';
import {items} from './data/dataMenu';

export default function Menux() {
  
  return (
    <div>
      <SideBarMenu items={items} card={card}/>
    </div>
  );
}
