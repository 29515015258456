import { SideBarMenuItem,SideBarMenuCard } from "../types/types";
import {FcPositiveDynamic,FcTodoList,FcCurrencyExchange,FcAutomatic,FcDebt,FcMoneyTransfer} from 'react-icons/fc';
import profileImage from "../assets/david.jpg";

export const card:SideBarMenuCard = {
    id:'card01',
    displayName:'Amanda Rodas',
    title:'Mamitalinda',
    photoUrl:profileImage,
    url:"/",
  }
  
export const items:SideBarMenuItem[] = [
    {
      id:'1',
      label:'Administración',
      icon: FcAutomatic,
      url: "#",
    },
    {
      id:'2',
      label:'Bases del día',
      icon: FcDebt,
      url: "#",
    },
    {
      id:'3',
      label:'Abonos del día',
      icon: FcCurrencyExchange,
      url: "#",
    },
    {
      id:'4',
      label:'Gastos del día',
      icon: FcMoneyTransfer,
      url: "#",
    },
    {
      id:'5',
      label:'Liquidación',
      icon: FcTodoList,
      url: "#",
    },
    {
      id:'6',
      label:'Reportes',
      icon: FcPositiveDynamic,
      url: "#",
    }

  ];