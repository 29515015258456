import React from 'react';
import  Menux  from './Components/menuPppal/Menux';

function App() {
  return (
    <div>
      <Menux/>
    </div>
  );
}

export default App;
